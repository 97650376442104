import axios from 'axios'
import Userauth from '../src/auth/js/index'
let baseUrl = process.env.VUE_APP_PUBLIC_BASEURL
// let baseUrl = `http://frausprotect.makeapk.com:7878/api`
var client = axios.create({
  baseURL: baseUrl,
  timeout: (1000 * 5 * 60)
});
export default {
  async execute(method, uri, data){
    try {
      let req =await client({
        method,
        url : uri,
        withCredentials : true,
        data : data
      })
      return req.data
    } catch (err) {
      console.log('Error in getting Server uri')
      console.log(err.response)
      if(err.response.status == 401){
        Userauth.ForcefullyLogout()
      }
      return err
    }
  }
}